import { createSlice } from "@reduxjs/toolkit";
export const GlobalConfig = createSlice({
  name: "globalConfig",
  initialState: {
    loading: false
  },
  reducers: {
    GlobalConfigSlice: (state, data) => {
        state.loading = data.payload;
    },
  },
});

export const { GlobalConfigSlice } = GlobalConfig.actions;

export default GlobalConfig.reducer;