import React, { Component, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { processRatingStars, fetchFlavorIcon } from "../../../helper/helper";
import product from "../../../data/product.json";
import { Collapse } from "react-bootstrap";
import { getRestaurants, getMenu, db } from "../../../firebase/calls";
import { useSelector, useDispatch } from "react-redux";
import { MenuItemSlice } from "../../../store/reducers/RestaurantMenu";
import {CartItemSlice} from "../../../store/reducers/CartItems";
import { nanoid as UUID } from "nanoid";

// import promobanner from "../../../assets/img/banner.jpg";
// import { BsCloudSnowFill } from "react-icons/bs";
// import { data } from "jquery";

const Foodlist = ({ updateCartItems,restaurantInfo }) => {
  let restaurantId = useParams();
  const [menuItems, setMenuItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const RestaurantMenu = useSelector(
    (state) => state?.RestaurantMenuList?.data
  );
  const venue = useSelector((state)=>state?.ReservationHandleSlice?.data);
  const dispatch = useDispatch();
  const [openVariant, setOpenVariant] = useState(false);
  const [variantOf, setVariantOf] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [singleItem, setSingleItem] = useState([]);
 
  const [parseVenue, setParseVenue]= useState()

  
  useEffect(() =>{
  //  console.log("xVal venue", venue) 
  },[venue,])

  restaurantId = restaurantId["id"];

  let fetchMenu = async (restaurantId) => {
    const data = await getMenu(db, restaurantId);
    dispatch(MenuItemSlice(data[0]));
    setMenuItems(data);
  };

  let addFoodItemsToCart = (e, item) => {
    // console.log("card menu", item);
    e.preventDefault();
    let order = {
      name: "",
      price: 0,
      description: "",
      quantity: 1,
    };

    order["name"] = item["menuItem"]["title"];
    order["price"] = item["menuItem"]["price"] / 100;
    order["description"] = item["description"] || "Description";
    const newCartItems = [...cartItems, order];
    updateCartItems(newCartItems);
    setCartItems(newCartItems);
    // console.log("new cart", newCartItems);
  };

  useEffect(() => {
    fetchMenu(restaurantId);
  }, []);

  useEffect(() => {}, [RestaurantMenu]);
  useEffect(() => {
    // console.log("lineItems", lineItems)
    dispatch(CartItemSlice(lineItems))
  }, [lineItems]);

  useEffect(() => {
    // console.log("singleItem", singleItem);
  }, [singleItem]);

  const onClick = () => {
    // console.log("test");
  };

  const collectArray = (item, i) => {
    const xxx = RestaurantMenu?.allMenuItems?.filter(
      (state) => state?.id == item
    );
    if (xxx) {
      let data = xxx[0];
      const uid = localStorage.getItem("uid");
      const phoneNumber = localStorage.getItem("userPhoneNumber");

      const mapCollect = (item) => {
        const pushItem = {
          item: {
            body: item?.body,
            id: item?.id,
            image: item?.image,
            price: item?.price,
            title: item?.title,
          },
        };
        singleItem[0] = pushItem
        setSingleItem([...singleItem]);
      };

      const addVariant = (item) => {
        const pushVariant = {
          variant: {
            id: item?.id,
            price: item?.price,
            title: item?.title,
          },
        };
        singleItem[1] = pushVariant
        setSingleItem([...singleItem]);
      };

      const modify1 = (item) => {
        let items = {
          id: data?.modifiers[0]?.id,
          title: data?.modifiers[0]?.title,
        };
        let options1 = [];
        const addCustomization1 = (item, data) => {
          let option = "options";
          options1.push(item);
          items[option] = [options1];
          // console.log("modify 1", items);
        };

        const onDone = () => {
          // console.log('done item', items)
          singleItem[2] = items
          setSingleItem([...singleItem]);
          // console.log('modify 1 single item', singleItem)
        }

        return (
          <div className="flex flex-col space-y-1 -mt-1 h-full w-full">
            <div onClick={onDone} className="flex justify-center items-center w-7 h-5 bg-red hover:bg-hoverRed self-end text-5 text-white hover:text-red hover:border hover:border-red">Done</div>
            {item.map((e, i) => (
              <span key={i} className="text-11 -pt-0.5">
                <input
                  onClick={() => addCustomization1(e)}
                  type="radio"
                  value={e}
                  className="h-2"
                />
                {e?.title}
              </span>
            ))}
          </div>
        );
      };

      const modify2 = (item) => {
        let items = {
          id: data?.modifiers[1]?.id,
          title: data?.modifiers[1]?.title,
        };
        let options2 = [];
        const addCustomization2 = (item, data) => {
          let option = "options";
          options2.push(item);
          items[option] = [options2];
          // console.log("modify 2 single item", items);
        };

        const onDone = () => {
          // console.log('done item', items)
          singleItem[3] = items
          setSingleItem([...singleItem]);
          // console.log('done single item', singleItem)
        }

        return (
          <div className="flex flex-col space-y-1 -mt-1 h-full w-full">
            <div onClick={onDone} className="flex justify-center items-center w-7 h-5 bg-red hover:bg-hoverRed self-end text-5 text-white hover:text-red hover:border hover:border-red">Done</div>
            {item.map((e, i) => (
              <span key={i} className="text-11 -pt-0.5">
                <input
                  onClick={() => addCustomization2(e)}
                  type="radio"
                  value={e}
                  className="h-2"
                />
                {e?.title}
              </span>
            ))}
          </div>
        );
      };
    
      const addToCart = () => {
        let iteme = "item";
        let variant = 'variant';
        let customizations = 'customizations';
        let options = 'options'
        let SingleObject = {
          id : UUID(),
          quantity: 1,
          restaurantName : restaurantInfo?.[0]?.restaurant?.name || ""
        }
        SingleObject[iteme] = {
          body: singleItem?.[0]?.item?.body,
          id: singleItem?.[0]?.item?.id,
          image: singleItem?.[0]?.item?.image,
          price: singleItem?.[0]?.item?.price,
          title: singleItem?.[0]?.item?.title
        }
        SingleObject[variant] = {
          id: singleItem?.[1]?.variant?.id,
          price: singleItem?.[1]?.variant?.price,
          title: singleItem?.[1]?.variant?.title
        }
        if (singleItem[2]){
          const custMix = {
            id: singleItem?.[2]?.id,
            title: singleItem?.[2]?.title,
          }
          custMix[options] = singleItem?.[2]?.options?.[0]
          const custMix2 = {
            id: singleItem?.[3]?.id,
            title: singleItem?.[3]?.title,
          }
          custMix2[options] = singleItem?.[3]?.options?.[0]
          SingleObject[customizations] = [
            custMix,
            custMix2
          ]
       
          const pushObj = SingleObject;
          setLineItems((prev)=>[...prev, pushObj]);
          SingleObject={};
          setSingleItem([]);
        } else {
          const pushObj = SingleObject;
          setLineItems((prev)=>[...prev, pushObj]);
          SingleObject={};
          setSingleItem([]);
        }
        setOpenVariant(false)
      }

      const isEmpty = () =>{
        // console.log('line item 1', lineItems, singleItem);
        let item= {
          body: '',
          id: '',
          image: '',
          price: '',
          title: '',
        }
        mapCollect(item);
        let x = [];
        setSingleItem(x)
        setVariantOf("");
        setOpenVariant(!openVariant);
        // console.log('line item 1', lineItems, singleItem);
      }

      const isNotEmpty = () => {
        // console.log('line item 2', lineItems, singleItem);
        setVariantOf("");
        let x = [];
        setSingleItem(x);
        setOpenVariant(true);
        setVariantOf(data?.title);
        mapCollect(data);
        // console.log('line item 2', lineItems, singleItem);
      }


      return (
        <div className={`flex flex-col w-full bg-white`}>
          <div
            className="flex w-full items-center cursor-pointer space-x-2"
            onClick={() => {
              variantOf === data?.title
                ? 
                isEmpty()
                : 
                isNotEmpty()
            }}
          >
            <img src={data?.image ? data?.image : ""} className="w-28 h-16" />
            <div className="flex flex-col">
              <span className="font-semibold text-15">{data?.title}</span>
              <span className=" text-11 text-gray line-clamp-1">
                {data?.body}
              </span>
            </div>
          </div>
          {openVariant && variantOf == data?.title && data?.variantGroup && (
            <div
              className={`flex w-full ${
                openVariant ? "h-40 py-2 px-1 overflow-auto bg-white" : ""
              }`}
            >
              <div className="flex flex-col h-full w-1/3 px-1">
                <span className=" font-semibold text-center">Variants</span>
                {data?.variantGroup && data?.variantGroup?.variants.map((e, i) => (
                  <div key={i} className="flex h-full w-full justify-between ">
                    <span className=" line-clamp-1">
                      <input
                        onClick={() => addVariant(e)}
                        type="radio"
                        value={e}
                        name="variant"
                        className="h-2"
                      />
                      {e?.title.split(" ")[0]}
                    </span>
                    <span>{'$'+e?.price/100}</span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col  w-1/3 border-l border-l-gray/20  p-1 ">
                {data?.modifiers && (
                  <span className="text-center w-full font-semibold">
                    {data?.modifiers[0]?.title}
                  </span>
                )}
                {data?.modifiers && modify1(data?.modifiers[0]?.options)}
              </div>
              <div className="flex flex-col  w-1/3 border-l border-l-gray/20 p-1">
                {data?.modifiers && (
                  <span className="text-center w-full font-semibold">
                    {data?.modifiers[1] && data?.modifiers[1]?.title}
                  </span>
                )}
                {/* {data?.modifiers && modify2(data?.modifiers[1]?.options)} */}
                {data?.modifiers && data?.modifiers[1] && modify2(data?.modifiers[1]?.options)}
              </div>
            </div>
          )}
          {openVariant && variantOf == data?.title && (
            <div onClick={addToCart} className="flex h-7 mr-10 w-24 self-end items-center justify-center bg-red hover:bg-hoverRed rounded text-white text-9 cursor-pointer">
              Add to cart
            </div>
          )}
          {/* {openVariant && variantOf == data?.title && <div onClick={()=>{setOpenVariant(false); setCustomizationIndex((prev)=>[...prev, options1]);setCustomizationIndex((prev)=>[...prev,options2])}} className="flex h-7 w-24 self-end items-center justify-center bg-red hover:bg-hoverRed rounded text-white text-9 cursor-pointer">Add to cart</div>} */}
        </div>
      );
    }
  };


  return (
    <div className="row">
      {/*
                <div className="col-lg-12">
                    <div className="promocodeimg mb-xl-20 p-relative">
                        <img src={promobanner} className="img-fluid full-width" alt="promocode" />
                        <div className="promocode-text">
                            <div className="promocode-text-content">
                                <h5 className="text-custom-white mb-2 fw-600">Get $10 off your first order!</h5>
                                <p className="text-custom-white no-margin">Spend $15 or more and get $10 off your first delivery order.</p>
                            </div>
                            <div className="promocode-btn"> <Link to="#">Get Deal</Link>
                            </div>
                        </div>
                    </div>
                </div>
                */}
      <div className="col-lg-12 restaurent-meal-head mb-md-40">
        <div className="card" >
          <div className="card-header">
            <div className="section-header-left">
              <h3 className="text-light-black header-title text-light-black no-margin">
                {/* <Link className="text-light-black no-margin" to="#" onClick={() => {}}
                                            aria-controls="user-profile"
                                            aria-expanded={onClick}> */}
                Menu Items
                {/* </Link> */}
              </h3>
            </div>
          </div>
          <Collapse in={onClick}>
            <div>
              <div className="card-body no-padding">
                {
                  venue ? 
                  <div className="flex flex-col w-full h-full space-x-0.5 items-center">
                  {RestaurantMenu?.allCollections?.map((e, i) => (
                    <div className="flex w-full flex-col" key={i}>
                      <span
                        className="text-16 text-center bg-gray/20 rounded-md font-semibold my-4"
                        key={i}
                      >
                        {e?.title.toUpperCase()}
                      </span>
                      {/* <div className='flex flex-col '>{collectArray(e?.title,e?.itemIds)}</div> */}
                      <div className="flex flex-col space-y-4 ">
                        {e?.itemIds?.map((itm, i) => collectArray(itm, i))}
                      </div>
                      {/* <span className='w-full h-[40px] bg-green/20 text-12'  key={i}>{collectArray(e?.title,e?.itemIds)}</span> */}
                    </div>
                  ))}
                </div>
                :
                <span>make a reservation 1st</span>
                }
              </div>
            </div>
          </Collapse>
        </div>
        {/* THIS IS ALL HARDCODED PRESET JSON BELOW FOR RATINGS, ETC. */}
        {/* <div className="card">
                        <div className="card-header">
                            <div className="section-header-left">
                                <h3 className="text-light-black header-title">
                                    <Link className="card-link text-light-black no-margin" to="#" onClick={() => {}}
                                            aria-controls="loan-progress"
                                            aria-expanded={onClick}>
                                        Combo Meals
                                    </Link>
                                </h3>
                            </div>
                        </div> */}
        {/* <Collapse in={onClick}> */}
        {/* <div>
                            <div className="card-body">
                                <div className="row">
                                    {product.map((item, i) => (
                                        <div key={i} className="col-lg-12">
                                            <div className="restaurent-product-list">
                                                <div className="restaurent-product-detail">
                                                    <div className="restaurent-product-left">
                                                        <div className="restaurent-product-title-box">
                                                            <div className="restaurent-product-box">
                                                                <div className="restaurent-product-title">
                                                                    <h6 className="mb-2"><Link to="#" className="text-light-black fw-600">{item.title}</Link></h6>
                                                                    <p className="text-light-white">{item.calories} Cal.</p>
                                                                </div>
                                                                <div className="restaurent-product-label">
                                                                    {
                                                                        item.label === true ? <span className="rectangle-tag bg-gradient-red text-custom-white">Label</span> : ''
                                                                    }
                                                                    {
                                                                        item.combo === true ? <span className="rectangle-tag bg-dark text-custom-white">Combo</span> : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="restaurent-product-rating">
                                                                <div className="ratings">
                                                                    {processRatingStars(item.rating)}
                                                                </div>
                                                                <div className="rating-text">
                                                                    <p className="text-light-white fs-12 title">{item.totalRating} ratings</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="restaurent-product-caption-box"> <span className="text-light-white">{item.text}</span>
                                                        </div>
                                                        <div className="restaurent-tags-price">
                                                            <div className="restaurent-tags">
                                                                {item.flavors.map((flavor, i) => (
                                                                    <span key={i} className="text-custom-white square-tag">
                                                                        <img src={fetchFlavorIcon(flavor)} alt={flavor} />
                                                                    </span>
                                                                ))}
                                                            </div> <span className="circle-tag">
                                                                <img src="assets/img/svg/heart.svg" alt="tag" />
                                                            </span>
                                                            <div className="restaurent-product-price">
                                                                <h6 className="text-success fw-600 no-margin">${item.price}+</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="restaurent-product-img">
                                                        <img src={item.img} className="img-fluid" alt="#" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            </div>
                        </Collapse>
                    </div> */}
      </div>
    </div>
  );
};

export default Foodlist;
