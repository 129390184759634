import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

const About = ({ restaurantInfo }) => {
    const [menuItems, setMenuItems] = useState([]);

    const onClick = () => {
        console.log('working')
    }

    const formatDate = (time, type) => {
      let hour;
      if (type === 'open') {
        return `${time / 60}:00 AM`
      }
      if (type === 'close') {
        return `${time / 60 - 12}:00 PM`
      }
    }
        return (
            <section className="section-padding restaurent-about smoothscroll" id="about">
                <div className="container">
                    {restaurantInfo.map(({ hours, restaurant },i) => (
                    <div className="row" key={i}>
                        <div className="col-md-6">
                            <h3 className="text-light-black fw-700 title">{restaurant.name} Information</h3>
                            <p className="text-light-green no-margin">{restaurant.categories[1]}, {restaurant.categories[2]}, {restaurant.categories[3]}</p>
                            {/*
                              <p className="text-light-white no-margin">{restaurant.slug}</p>
                            */}
                            <span className="text-success fs-16">$</span>
                            <span className="text-success fs-16">$</span>
                            <span className="text-success fs-16">$</span>
                            <span className="text-dark-white fs-16">$</span>
                            <span className="text-dark-white fs-16">$</span>
                            <ul className="about-restaurent">
                                <li> <i className="fas fa-map-marker-alt" />
                                    <span>
                                        <Link to="#" className="text-light-white">
                                            {restaurant.street || 'Street'}<br />
                                          {restaurant.city || 'City'}, {restaurant.state || 'State'}, {restaurant.zip || 'Zipcode'}
                                        </Link>
                                    </span>
                                </li>
                                {/* <li> <i className="fas fa-phone-alt" />
                                    <span><Link to="tel:" className="text-light-white">(347) 123456789</Link></span>
                                </li>
                                <li> <i className="far fa-envelope" />
                                    <span><Link to="mailto:" className="text-light-white">demo@domain.com</Link></span>
                                </li> */}
                            </ul>
                            {/*
                            <ul className="social-media pt-2">
                                <li> <Link to="#"><i className="fab fa-facebook-f" /></Link>
                                </li>
                                <li> <Link to="#"><i className="fab fa-twitter" /></Link>
                                </li>
                                <li> <Link to="#"><i className="fab fa-instagram" /></Link>
                                </li>
                                <li> <Link to="#"><i className="fab fa-pinterest-p" /></Link>
                                </li>
                                <li> <Link to="#"><i className="fab fa-youtube" /></Link>
                                </li>
                            </ul>
                            */}
                        </div>
                        <div className="col-md-6">
                            <div className="restaurent-schdule">
                                <div className="card">
                                    <div className="card-header text-light-white fw-700 fs-16">Hours</div>
                                        <div className="card-body">
                                          {
                                            Object.keys(hours).map((day,i) => (
                                              <div className="schedule-box" key={i}>
                                                  <div className="day text-light-black">{day}</div>
                                                  <div className="time text-light-black">
                                                    {formatDate(hours[day].open, 'open')} - {formatDate(hours[day].close, 'close')}
                                                  </div>
                                              </div>
                                            ))
                                          }
                                            {/*restaurant['hours'].map((hours) => (
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">{hours.dayRange}</div>
                                                    {hours.sectionHours.slice(0,1).map((hour) => (
                                                        <div className="time text-light-black">{hour.startTime} : {hour.endTime}</div>
                                                    ))}
                                                </div>
                                            ))*/}
                                        {/* <Collapse in={onClick}>
                                            <div>
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Tuesday</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                                </div>
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Wednesday</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                                </div>
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Thursday</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                                </div>
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Friday</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                                </div>
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Saturday</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                                </div>
                                                <div className="schedule-box">
                                                    <div className="day text-light-black">Sunday</div>
                                                    <div className="time text-light-black">Delivery: 7:00am - 10:00pm</div>
                                                </div>
                                            </div> */}
                                        {/* </Collapse> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </section>
        );
}


export default About;
