import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {totalAmountHandler} from "../../../store/reducers/amountReducer"

export default function TotalPrice({items}) {
  
  
  const [numb, setNumb] = useState()
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state?.CartList?.data);

  // const total = cartData.reduce((accumulator, item) => {
  //   let itemPrice = item?.item?.price;
  //   let variantPrice = item?.variant?.price
  //   return accumulator + itemPrice + variantPrice;
  // }, 0);
  
  // console.log(total);

// console.log(sum); // Output: 15



  useEffect(() => {

    const totalPrice = cartData.reduce((accumulator, item) => {
      let itemPrice = item?.item?.price;
      let variantPrice = item?.variant?.price ? item?.variant?.price : 0;
      console.log("hehehe item price", itemPrice)
      console.log("hehehe variant price", variantPrice)
      return accumulator + itemPrice + variantPrice;
    }, 0);
   
    // const formattedTotal = (totalPrice.toFixed(2) * 100);
    // const NumOne = formattedTotal / 100;
    setNumb(totalPrice)
    dispatch(totalAmountHandler(totalPrice))  
  }, [cartData]);
  

  return (
    <div className="item-total justify-between ">
      <div className="total-price border-0">
        <span className="text-dark-white fw-700">Items subtotal:                </span>
        <span className="text-dark-white fw-700">{'$' + numb / 100}</span>
      </div>
    </div>
  );
}
