import { createSlice } from "@reduxjs/toolkit";
export const ReservationHandler = createSlice({
  name: "Reservation",
  initialState: {
    name: 'Reservation',
    data: {}
  },
  reducers: {
    ReservationSlice: (state, data) => {
      // console.log("store action==>",JSON.parse(data.payload))  
      state.data = JSON.parse(data.payload);
    },
  },
});

export const { ReservationSlice } = ReservationHandler.actions;

export default ReservationHandler.reducer;