import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsCalendar3, BsClock } from 'react-icons/bs';
import { IoHourglassSharp, IoChatboxEllipsesOutline, IoPersonOutline } from 'react-icons/io5';
import { RiBookmark3Fill } from 'react-icons/ri';
import DatePicker from "react-datepicker";
import {
  checkExisitingReservation, saveReservation, joinTableSession,
} from '../../../firebase/calls';

import "react-datepicker/dist/react-datepicker.css";
import {ReservationSlice} from "../../../store/reducers/ResevervatinCheck";
import { useDispatch } from 'react-redux';

const EventTypes = ['Birthday', 'Anniversary', 'Wedding', 'Bridal Party', 'Celebration', 'Work Event'];
const AllergyTypes = [
  'Dairy', 'Eggs', 'Fish', 'Shellfish', 'Gluten','Milk',
  'Nuts', 'Peanuts', 'Sesame Seed', 'Milk', 'Soy', 'Wheat',
];

const RestaurantActions = ({ restaurantInfo }) => {
  const [showPeopleSelector, setShowPeopleSelector] = useState(false);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [showReservationView, setShowReservationView] = useState(false);

  const [numPeople, setNumPeople] = useState(2);
  const [selectedTime, setSelectedTime] = useState('ASAP');
  const [specialRequest, setSpecialRequest] = useState('');
  const [selectedOccasion, setSelectedOccasion] = useState('');
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [reservationDate, setReservationDate] = useState(new Date());
  const [reservationCode, setReservationCode] = useState('');
  const [reservationCreated, setReservationCreated] = useState(false);
  const [checkedForRes, setCheckedForRes] = useState(false);
  const dispatch = useDispatch();

  const renderPeopleSelector = () => {
    const options = [1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,10,10];
    return options.map((itm, idx) => {
      const className = numPeople === idx + 1
        ? "restaurant-action-num-option restaurant-action-num-active"
        : "restaurant-action-num-option"
      return (
        <div className={className} onClick={() => {setNumPeople(idx + 1); setShowPeopleSelector(false);}} key={idx}>
          {idx + 1}
        </div>
      )
    });
  }
  useEffect(()=>{
    if(reservationCode){
      finalReserve()
    }
    
  },[reservationCode])

  const renderTimeSelector = () => {
    const options = [
      'ASAP',1,2,3,4,5,6,7,8,9,10,11,12,13,14,
      15,16,17,18,19,20,21,22,23,24,
    ];
    return options.map((itm, idx) => {
      const timeDisplay = itm === 'ASAP'
        ? 'ASAP'
        : itm > 12
          ? `${itm - 12}:00 PM`
          : `${itm}:00 AM`;
        const className = selectedTime === timeDisplay
          ? "restaurant-action-time-option restaurant-action-time-active"
          : "restaurant-action-time-option"
      return (
        <div className={className} onClick={() => {setSelectedTime(timeDisplay); setShowTimeSelector(false);}} key={idx}>
          {timeDisplay}
        </div>
      )
    });
  }

  const toggleReservationView = () => {
    setShowReservationView(!showReservationView)
  }

  const handleAllergySelect = (allergy, action) => {
    if (action === 'add') {
      setSelectedAllergies([...selectedAllergies, allergy])
      return;
    }
    const newAllergies = selectedAllergies.filter((itm) => itm !== allergy);
    setSelectedAllergies(newAllergies);
  }

  const tableCodeGenerator = () => {
    const code = Math.floor(Math.random()*90000) + 10000;
    // console.log('code', code.toString())
    let xcode = code.toString()
    setReservationCode(xcode);
  }

  const finalReserve = async() => {
    const reservationObj = {
      numPeople,
      selectedTime,
      specialRequest,
      selectedOccasion,
      selectedAllergies,
      reservationDate,
      reservationCode,
      venue: restaurantInfo[0],
    }
    // console.log('object',reservationObj)
    const resp = await saveReservation(reservationObj);
    setReservationCreated(resp.result);
    getReservation();
  }

  const handleSave =  () => {
    tableCodeGenerator();
  }

  const getReservation = async () => {
    const resp = await checkExisitingReservation(restaurantInfo[0]);
    // dispatch(ReservationSlice(resp))
    let serializeResponse = JSON.stringify(resp)
    dispatch(ReservationSlice(serializeResponse))
    // console.log('3nd resp', resp)
      if (resp) {
        const { id, guestCount, code, tags, reservationDate, notes } = resp;
        setNumPeople(guestCount);
        setSelectedTime('ASAP');
        setSpecialRequest(notes);
        setSelectedOccasion([]);
        setSelectedAllergies([]);
        reservationDate?.seconds && setReservationDate(new Date(reservationDate?.seconds * 1000));
        setReservationCode(code);
        setReservationCreated(true)
      }
  }

  const joinSession = async () => {
    joinTableSession(restaurantInfo[0]);
  }

  useEffect(() => {
    if (restaurantInfo.length !== 0 && !checkedForRes) {
      getReservation();
      setCheckedForRes(true);
    }
  });

  return (
    <div className="restaurant-action-container">
      <div className="restaurant-action-button-container">
        <div
          className={showPeopleSelector ? "restaurant-action-button restaurant-action-button-active" : "restaurant-action-button"}
          onClick={() => setShowPeopleSelector(!showPeopleSelector)}
        >
           <IoPersonOutline /><span>{numPeople}</span>
        </div>
        <div className="restaurant-action-button" />
        <div className="restaurant-action-button">
          <BsCalendar3 />
          <DatePicker selected={reservationDate} onChange={(date) => setReservationDate(date)} />
        </div>
        <div
          className={showTimeSelector ? "restaurant-action-button restaurant-action-button-active" : "restaurant-action-button"}
          onClick={() => setShowTimeSelector(!showTimeSelector)}
        >
          <BsClock /><span>{selectedTime}</span>
        </div>
      </div>
      {showPeopleSelector && (
        <div className="restaurant-action-num-container">
          <div className="restaurant-action-scroll">
            { renderPeopleSelector() }
          </div>
        </div>
      )}
      {showTimeSelector && (
        <div className="restaurant-action-time-container">
          <div className="restaurant-action-wrap-scroll">
            { renderTimeSelector() }
          </div>
        </div>
      )}
      {
        reservationCreated ? (
          <div className="reservation-card">
            <div className="reservation-card-header">
              <div><RiBookmark3Fill size={30} /></div>
              <h5>Reservation</h5>
            </div>
            <div className="reservation-card-section">
              <div className="reservation-card-small-title">
                <BsCalendar3 /><span style={{marginLeft: 5}}>Date</span>
              </div>
              <div className="reservation-card-value">
                {reservationDate.toDateString()} {selectedTime}
              </div>
            </div>
            <div className="reservation-card-section">
              <div className="reservation-card-small-title">
                <IoPersonOutline /><span style={{marginLeft: 5}}>{numPeople} Guest{numPeople > 1 && 's'}</span>
              </div>
              <div>Guest List</div>
            </div>
            <div className="reservation-card-section" onClick={() => joinSession()}>
              <div className="reservation-card-small-title">
                Invite code
              </div>
              <div className="reservation-card-value">
                {reservationCode}
              </div>
            </div>
          </div>
        ) : showReservationView ? (
          <div>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
              <h1>
                Confirm Reservation
              </h1>
              <span className="cancel-button" onClick={() => toggleReservationView()}>Cancel</span>
            </div>
            <div className="reservation-form-section">
              <div>
                <b>Any special requests?</b>
              </div>
              <textarea
                className="special-request-textarea resize-none border mt-1"
                value={specialRequest}
                onChange={(e) => setSpecialRequest(e.target.value)}
              />
            </div>
            <div className="reservation-form-section">
              <div>
                <b>What's the occasion?</b>
              </div>
              <div>
                {
                  EventTypes.map((event,idx) =>
                    <button
                      className={event === selectedOccasion ? "event-type-button-fill" : "event-type-button"}
                      onClick={() => setSelectedOccasion(event)}
                      key={idx}
                    >
                      {event}
                    </button>)
                }
              </div>
            </div>
            <div className="reservation-form-section">
              <div>
                <b>Any allergies?</b>
              </div>
              <div>
                {
                  AllergyTypes.map((allergy,idx) => {
                    const action = selectedAllergies.includes(allergy)
                      ? 'remove'
                      : 'add';
                    const className = action === 'add'
                      ? 'allergy-type-button'
                      : 'allergy-type-button-fill';
                    return (
                      <button className={className} onClick={() => handleAllergySelect(allergy, action)} key={idx}>
                        {allergy}
                      </button>
                    )
                  })
                }
              </div>
            </div>
            <button className="reserve-button" onClick={() => handleSave()}>
              Reserve
            </button>
          </div>
        ) : (
          <div className="restaurant-action-options-container">
            <div className="restaurant-action-option" onClick={() => toggleReservationView()}>
              <div>
                <span><BsCalendar3 color="#2979ff" size={17} /></span>
                <div>
                  <span>Create Reservation</span> 
                  <span>Select from avaiable time slots</span>
                </div>
              </div>
            </div>
            <div className="restaurant-action-option" onClick={() => toggleReservationView()}>
              <div>
                <span><IoHourglassSharp color="#9c27b0" size={17} /></span>
                <div>
                  <span>Join Waitlist</span>
                  <span>~ 30 min wait time</span>
                </div>
              </div>
            </div>
            <div className="restaurant-action-option" onClick={() => joinSession()}>
              <div>
                <span><IoChatboxEllipsesOutline color="#3f51b5" size={17} /></span>
                <div>
                  <span>Join Table Session</span>
                  <span>Enter 5 digit code to join a table in progress</span>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

  export default RestaurantActions;
