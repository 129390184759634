import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute.js";
import loadingGif from "./assets/images/loading.gif";
// without lazyload pages
import Restaurantone from "./components/pages/Restaurantone.js";
import { useSelector } from "react-redux";
// Lazy-loaded components
const Home = React.lazy(() => import("./components/pages/Home.js"));
const About = React.lazy(() => import("./components/pages/About.js"));
const Addrestaurant = React.lazy(() =>
  import("./components/pages/Addrestaurant.js")
);
const Checkout = React.lazy(() => import("./components/pages/Checkout.js"));
const Exdeals = React.lazy(() => import("./components/pages/Exdeals.js"));
const Geolocator = React.lazy(() => import("./components/pages/Geolocator.js"));
const Listview = React.lazy(() => import("./components/pages/Listview.js"));
const Login = React.lazy(() => import("./components/pages/Login.js"));
const Register = React.lazy(() => import("./components/pages/Register.js"));
const Orderdetails = React.lazy(() =>
  import("./components/pages/Orderdetails.js")
);
const Contact = React.lazy(() => import("./components/pages/Contact.js"));
const Error = React.lazy(() => import("./components/pages/Error.js"));
const TestingFirebase = React.lazy(() =>
  import("./components/pages/Testing.js")
);

function App() {
  const isLoading = useSelector((state) => state?.GlobalConfig?.loading);
  return (
    <Router>
      <div
        className={`d-flex flex-column min-vh-100 bg-light position-relative ${
          isLoading && "disabled"
        }`}
      >
        {isLoading && (
          <div className="d-flex justify-content-center page-loader">
            <img src={loadingGif} alt="loading-gif" className="loaderWidth"/>
          </div>
        )}
        <Suspense fallback={<LoadingFallback />}>
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/test" component={TestingFirebase} />
            <Route exact path="/about" component={About} />
            <PrivateRoute
              exact
              path="/restaurant/:id"
              component={Restaurantone}
            />
            <PrivateRoute
              exact
              path="/restaurant-v1"
              component={Restaurantone}
            />
            <PrivateRoute
              exact
              path="/add-restaurant"
              component={Addrestaurant}
            />
            <PrivateRoute exact path="/checkout" component={Checkout} />
            <PrivateRoute exact path="/ex-deals" component={Exdeals} />
            <PrivateRoute exact path="/geo-locator" component={Geolocator} />
            <PrivateRoute exact path="/list-view" component={Listview} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <PrivateRoute
              exact
              path="/order-details"
              component={Orderdetails}
            />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/error-page" component={Error} />
            <Route component={Error} />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

// Loading fallback component
const LoadingFallback = () => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{ minHeight: "100vh" }}
  >
    <img src={loadingGif} alt="loading-gif" />
  </div>
);

export default App;
