import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import About from './About.js';
import Address from './Address.js';
import Details from './Details.js';
import Mapgallery from './Mapgallery.js';
import Meals from './Meals.js';
import Offer from './Offer.js';
import Review from './Review.js';
import Tabs from './Tabs.js';
import Top from './Top.js';
import RestaurantActions from './RestaurantActions.js';
import { getSingleRestaurantInfo } from '../../../firebase/calls.js';

const Content = ({ cartItemsCallback }) => {
  const [restaurantInfo, setRestaurantInfo] = useState([]);
  // const [cartItems, setCartItems] = useState([]);
  const history = useHistory();
  const restaurantId = useParams();

  const fetchRestaurantInfo = async () => {
    const { venueId } = history.location.state;
    // console.log('fresh venueId', venueId)
    // console.log('fresh restaurantId', restaurantId)

    const data = await getSingleRestaurantInfo(venueId, restaurantId.id);
    // console.log('fresh start', data)
    console.log('restaurantId data import', data)
    setRestaurantInfo(data);
  }


//   const updateCartItems = (cartItems) => {
// console.log('cart item rec', cartItems)
//     // setCartItems(cartItems);
//   }


  useEffect(() => {
    fetchRestaurantInfo();
  },[])

  const finalMenu = (item)=>{
    cartItemsCallback(item)
  }

  return (
    <Fragment>
      <Top restaurantInfo={restaurantInfo} />
      <Details restaurantInfo={restaurantInfo}  />
      <Tabs/>
      <Address/>
      <RestaurantActions restaurantInfo={restaurantInfo} />
      <Meals cartItemsCallback={finalMenu} restaurantInfo={restaurantInfo} />
      <About restaurantInfo={restaurantInfo}/>
      <Mapgallery restaurantInfo={restaurantInfo}/>
      {/* <Review/> */}
      {/* <Offer/> */}
    </Fragment>
  );
}

export default Content;
