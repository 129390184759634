import { createSlice } from "@reduxjs/toolkit";
export const CartHandler = createSlice({
  name: "cart",
  initialState: {
    // name: 'MenuList',
    data: []
  },
  reducers: {
    CartItemSlice: (state, data) => {
        state.data = data.payload;
    },
  },
});

export const { CartItemSlice } = CartHandler.actions;

export default CartHandler.reducer;