import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
// install Swiper components
SwiperCore.use([Navigation]);

const Top = ({ restaurantInfo }) => {
  const bannerImages = restaurantInfo?.[0]?.restaurant?.bannerImages?.length
    ? restaurantInfo?.[0]?.restaurant?.bannerImages
    : [
        "https://firebasestorage.googleapis.com:443/v0/b/seefood-adbba.appspot.com/o/images%2F751B264B-3E78-49E0-9AEE-8735D2DD5A95.jpg?alt=media&token=0ae957a9-78d2-4f55-a33c-e98619e7897a",
      ];

  console.log(bannerImages, "restaurantInfo[0]");

  const settings = {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    speed: 1000,
    grabCursor: true,
    watchSlidesProgress: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
  };
  return (
     <div>
      <Swiper
        tag="section"
        className="top swiper-container"
        {...settings}
        navigation={bannerImages?.length > 1 ? true : false}
      >
        {bannerImages.map((imageSrc, i) => (
          <SwiperSlide key={i} className="swiper-slide slide-item">
            <img src={imageSrc}alt=""  />
          </SwiperSlide>
        ))}
      </Swiper>
      </div>
   
  );
};

export default Top;
