

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import amountSlice from "./reducers/amountReducer";
import ReservationHandleSlice from "./reducers/ResevervatinCheck";
import RestaurantMenuList from "./reducers/RestaurantMenu";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import CartList from "./reducers/CartItems";
import GlobalConfig from "./reducers/globalConfig";

const appReducer = combineReducers({
  totalAmount: amountSlice,
  ReservationHandleSlice: ReservationHandleSlice,
  RestaurantMenuList: RestaurantMenuList,
  CartList: CartList,
  GlobalConfig: GlobalConfig,
});
const persistConfig = {
  key: "root",
  storage,
  safelist: ["CartList"], // only persist CartList
};
const rootReducer = (
  state,
  action
) => {
  if (action.type === "LOG_OUT") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
