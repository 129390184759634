import { createSlice } from "@reduxjs/toolkit";
export const MenuHandler = createSlice({
  name: "Menu Item List",
  initialState: {
    // name: 'MenuList',
    data: {}
  },
  reducers: {
    MenuItemSlice: (state, data) => {
        state.data = data.payload;
    },
  },
});

export const { MenuItemSlice } = MenuHandler.actions;

export default MenuHandler.reducer;