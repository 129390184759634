function calculateRating(rating) {
    if (rating < 3) {
        return 'bg-red';
    } else if (rating > 3 && rating < 4) {
        return 'bg-yellow';
    } else {
        return 'bg-green';
    }
}
function processRatingStars(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(<i className="fas fa-star" key={i}></i>);
    }
    if (rating && rating > 0) {
        for (let i = 0; i <= rating - 1; i++) {
            stars[i] = <i className="fas fa-star text-yellow" key={i}></i>;
        }
    }
    return stars;
}
function restaurantRatingStars(rating) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
        stars.push(<span className="fs-16" key={i}><i className="fas fa-star"></i></span>);
    }
    if (rating && rating > 0) {
        for (let i = 0; i <= rating - 1; i++) {
            stars[i] = <span className="text-yellow fs-16" key={i}><i className="fas fa-star"></i></span>;
        }
    }
    return stars;
}
function fetchFlavorIcon(flavor) {
    return 'assets/img/svg/' + flavor + '.svg';
}

function formatDate(date) {
    if(date instanceof Date){
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];
        
          const month = months[date?.getMonth()];
          const day = date?.getDate();
          const year = date?.getFullYear();
          let hours = date?.getHours();
          const minutes = date?.getMinutes();
          const period = hours >= 12 ? "pm" : "am";
        
          // Convert hours from 24-hour format to 12-hour format
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
        
          const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;
        
          return `${month} ${day}, ${year} ${formattedTime}`;
    }
  }
export { calculateRating, processRatingStars, fetchFlavorIcon, restaurantRatingStars,formatDate };
