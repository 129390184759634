import React, { Component, Fragment, useState } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header.js";
import Footer from "../layouts/Footertwo.js";
import Content from "../sections/restaurant/Content.js";

export default function Restaurantone() {
  const [restaurants, setRestaurants] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const restaurantsCallback = (restaurants) => {
    setRestaurants(restaurants);
  };

  const cartItemsCallback = (cartItems) => {
    console.log("content", cartItems);
    setCartItems(cartItems);
  };

  return (
    <Fragment>
      <MetaTags>
        <title>SeeFood360 | Restaurant</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header restaurantsCallback={restaurantsCallback} cartItems={cartItems} />
      <Content cartItemsCallback={cartItemsCallback} />
      <Footer />
    </Fragment>
  );
}
