import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";
import Offerform from "../../layouts/Offerform";
import Testimonial from "../../layouts/Testimonial";
import Video from "../../layouts/Video";
import Foodlist from "./Foodlist";
import TotalPrice from "./CartTotalValue";
import { useSelector, useDispatch } from "react-redux";
import { CartItemSlice } from "../../../store/reducers/CartItems";

import { sendOrder } from "../../../firebase/calls";

const Meals = ({ cartItemsCallback, restaurantInfo }) => {
  const checkReservation = useSelector(
    (state) => state?.ReservationHandleSlice?.data
  );
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const phoneNumber = localStorage.getItem("userPhoneNumber");
  const [lineItems, setLineItmes] = useState({});
  const uid = localStorage.getItem("uid");
  const cartData = useSelector((state) => state?.CartList?.data);
  const [isCheckout, setIsCheckOut] = useState(false);

  useEffect(() => {}, [cartData]);

  const updateCartItems = (cartItems) => {
    // cartItems.map((e) => console.log("card menus", e));
    setCartItems(cartItems);
    cartItemsCallback(cartItems);
  };

  useEffect(() => {
    // console.log("check Res", checkReservation);

    // console.log("check Res", lineItems);
  }, [checkReservation, lineItems]);

  const saveOrder = async () => {
    // console.log("restaurantInfo[0]", restaurantInfo[0]);
    // console.log("cartData", cartData);
    const res = await sendOrder(restaurantInfo[0], cartData);
    setIsCheckOut(true);
  };

  const deleteCartItem = (item) => {
    let xyz = [];
    dispatch(CartItemSlice(xyz));
    const xxx = cartData.filter((state) => state?.id != item?.id);
    dispatch(CartItemSlice(xxx));
  };

  return (
    <section className="section-padding restaurent-meals bg-light-theme">
      <div className="container-fluid">
        <div className="row">
          {/*
                          <div className="col-xl-3 col-lg-3">
                              <StickyBox class="stickysidebar" offsetTop={0} offsetBottom={20}>
                                  <Testimonial />
                                  <Offerform />
                              </StickyBox>
                          </div>
                        */}
          <div className="col-xl-9 col-lg-6" id="menu">
            <Foodlist updateCartItems={updateCartItems} restaurantInfo={restaurantInfo} />
          </div>
          <div className="col-xl-3 col-lg-3">
            <StickyBox class="stickysidebar" offsetTop={0} offsetBottom={20}>
              <div className="sidebar">
                {/* <Video /> */}
                <div className="cart-detail-box">
                  <div className="card">
                    <div className="card-header padding-15 fw-700">
                      Your Order
                    </div>
                    {cartData.length === 0 && (
                      <div className="empty-bag padding-15 fw-700">
                        Empty Cart
                      </div>
                    )}
                    <div className="card-body no-padding" id="scrollstyle-4">
                      {cartData?.map((e, i) => (
                        <div key={i} className="cat-product-box">
                          <div className="cat-product">
                            <div className="cat-name">
                              {/* <Link to="#"> */}
                              <p className="text-light-green fw-700 line-clamp-1">
                                <span className="text-dark-white">
                                  {e?.quantity}
                                </span>{" "}
                                {/* Chilli Chicken Pizza */}
                                {e?.item?.title}
                              </p>{" "}
                              <span className="text-light-white fw-700">
                                {/* small, chilli chicken */}
                                {e?.item?.body}
                              </span>
                              {/* </Link> */}
                            </div>

                            <div className=" flex w-ful mt-0.5 items-center price">
                              <svg
                                onClick={() => deleteCartItem(e)}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-3 stroke-red"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 
                                  1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 
                                  2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 
                                  .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 
                                  0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 
                                  1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                              {(
                                e?.item?.price.toFixed(2) / 100 +
                                (e?.variant?.price
                                  ? e?.variant?.price / 100
                                  : 0)
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ))}
                      <TotalPrice items={cartData} />
                    </div>

                    <div className="card-footer padding-15">
                      {isCheckout ? (
                        <Link
                          to={{ pathname: "/checkout", state: { cartItems } }}
                          className="btn-first green-btn text-custom-white full-width fw-500"
                        >
                          Proceed to Checkout
                        </Link>
                      ) : (
                        <div
                          onClick={() => saveOrder()}
                          className={`btn-first green-btn text-custom-white full-width fw-500 ${cartData.length === 0 && "disabled-btn"}`}
                        >
                          Place Order
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </StickyBox>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meals;
