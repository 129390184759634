import React, { Component, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getRestaurants,
  getMenu,
  db,
  getSingleRestaurantInfo,
} from "../../../firebase/calls";
import $ from "jquery";
import "magnific-popup";
import gallery from "../../../data/restaurantgallery.json";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
import GoogleMapReact from "google-map-react";

const Mapgallery = ({ restaurantInfo }) => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const onClick = () => {
    console.log("working");
  };
  return (
    <div
      className="map-gallery-sec section-padding bg-light-theme smoothscroll"
      id="mapgallery"
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="main-box">
              <div className="row">
                <div className="col-md-12 map-pr-0">
                  {/* <iframe id="locmap" title="map" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&t=&z=13&ie=UTF8&iwloc=&output=embed" />
                   */}
                  <div
                    className="map"
                    style={{ height: "55vh", width: "100%", margin: "auto" }}
                  >
                    {restaurantInfo?.map((restaurant, index) => {
                        return  <div className="google-map" key={index}>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: "" }}
                          defaultCenter={defaultProps.center}
                          defaultZoom={defaultProps.zoom}
                        >
                          <AnyReactComponent
                            lat={restaurant.lat}
                            lng={restaurant.lng}
                            text="My Marker"
                          />
                        </GoogleMapReact>
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mapgallery;
