/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import {
  httpsCallable,
  httpsCallableFromURL,
  getFunctions,
} from "firebase/functions";
import { v4 as uuidv4 } from "uuid";
// import { doc, setDoc } from "firebase/firestore";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  doc,
  addDoc,
  setDoc,
  getFirestore,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
  where,
} from "firebase/firestore/lite";
import { nanoid as UUID } from "nanoid";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
const restaurantCollection = collection(db, "restaurant");
const venuesCollection = collection(db, "venues");
const Orders = collection(db, "orders");

const getRestaurants = async () => {
  // let data = [];

  // const q = query(restaurantCollection, orderBy("name", "asc"), limit(100));
  // const querySnapshot = await getDocs(q);
  // querySnapshot.forEach((doc) => {
  //   let restaurant = doc.data()
  //   // doc.data() is never undefined for query doc snapshots
  //   data.push(restaurant);
  // });

  // console.log('restaurantCollection', restaurantCollection);
  let data2 = [];
  const q2 = query(venuesCollection, orderBy("name", "asc"), limit(10));
  const querySnapshot2 = await getDocs(q2);
  querySnapshot2.forEach((doc) => {
    let venue = doc.data();
    // doc.data() is never undefined for query doc snapshots
    data2.push(venue);
  });

  // return data;
  return data2;
};

async function fetchMenu() {
  if (!restaurant.id) {
    return;
  }
}

const getSingleRestaurantInfo = async (venueId, restaurantId) => {
  let data = [];
  const q = query(venuesCollection, orderBy("name", "asc"), limit(10));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let restaurant = doc.data();
    if (
      restaurant["restaurant"] &&
      restaurant["id"] === venueId &&
      restaurant["restaurant"]["id"] === restaurantId
    )
      data.push(restaurant);
    // doc.data() is never undefined for query doc snapshots
  });
  return data;
  // return []
};

const getMenu = async (db, restaurantId) => {
  // const id = restaurantId // we will plug in the ID of the restaurant here to get the collection
  // let menuUrl = 'restaurant/' + id + '/menu'
  // const coll = collection(db, menuUrl)
  // let data = [];
  // const q = query(coll, orderBy("title", "asc"), limit(100));
  // const querySnapshot = await getDocs(q)
  // querySnapshot.forEach((doc) => {
  //   let restaurant = doc.data()
  //   data.push(restaurant);
  // })

  // we will plug in the ID of the restaurant here to get the collection
  /**
  
 */

  // eslint-disable-next-line no-constant-condition
  if (true) {
    let menuItems = [];
    let sections = [];
    /// new implementation
    try {
      // Query for catalog items
      let catelogUrl = `business/${restaurantId}/catalog`;
      const catalogRef = collection(db, catelogUrl);
      const catalogSnapshot = await getDocs(query(catalogRef));
      const catalog = [];

      catalogSnapshot.forEach((doc) => {
        const catalogItem = doc.data();
        catalog.push(catalogItem);
      });

      // Group catalog items by type
      const dict = catalog.reduce((acc, item) => {
        acc[item.type] = acc[item.type] || [];
        acc[item.type].push(item);
        return acc;
      }, {});

      let menuItems = (dict["MenuItem"] || [])
        .map((item) => item.menuItem)
        .filter(Boolean);
      const menuCollections = (dict["Collection"] || [])
        .map((item) => item.collection)
        .filter(Boolean);
      const menus = (dict["Menu"] || [])
        .map((item) => item.menu)
        .filter(Boolean);

      const featuredMenuItems = menuItems.slice(0, 5);

      const activeMenu = menus.reduce((maxMenu, menu) => {
        return menu.collectionIds.length >
          (maxMenu ? maxMenu.collectionIds.length : 0)
          ? menu
          : maxMenu;
      }, null);

      if (!activeMenu) {
        sections = [
          {
            model: {
              id: UUID(),
              title: "All Menu Items",
              subtitle: "",
              itemIds: menuItems.map((item) => item.id).filter(Boolean),
              // image: restaurant.bannerImages ? restaurant.bannerImages[0] : null,
            },
            allMenuItems: menuItems,
          },
        ];
        return sections;
      }

      sections = [
        {
          model: activeMenu,
          allCollections: menuCollections,
          allMenuItems: menuItems,
        },
      ];
    } catch (error) {
      console.log(error.message);
      // throw error;
    }

    return sections;
  } else {
    let catelogUrl = `business/${restaurantId}/catalog`;
    const coll2 = collection(db, catelogUrl);
    let data2 = [];

    const q2 = query(coll2, orderBy("id", "asc"), limit(10));
    const querySnapshot2 = await getDocs(q2);

    querySnapshot2.forEach((doc) => {
      let restaurant = doc.data();
      data2.push(restaurant);
    });

    const menuItems = data2.filter((itm) => itm.type === "MenuItem");
    return menuItems;
  }
};

const initRecaptcha = () => {
  const auth = getAuth();

  window.recaptchaVerifier = new RecaptchaVerifier(
    "send-verify",
    {
      size: "invisible",
      callback: (response) => {},
    },
    auth
  );
};

const sendSMSVerificationCode = async (phoneNumber) => {
  const auth = getAuth();
  const phoneNumberFormatted = `${phoneNumber}`;
  const appVerifier = window.recaptchaVerifier;

  try {
    // Sends SMS Validation Code
    window.confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumberFormatted,
      appVerifier
    );

    return { codeSent: true };
  } catch (e) {
    // SMS Validation Code Not Sent
    return { codeSent: false, error: e };
  }
};

const validateVerificationCode = async (code) => {
  const auth = getAuth();
  try {
    const confirmResult = await window.confirmationResult.confirm(code);
    // User is successfully signed in
    const { user } = confirmResult;
    localStorage.setItem("accessToken", user.accessToken);
    localStorage.setItem("uid", user.uid);
    localStorage.setItem("userPhoneNumber", user.reloadUserInfo.phoneNumber);
    return { isValid: true, user };
  } catch (e) {
    // User is not signed in
    return { isValid: false, error: e };
  }
};

const validateVerificationCodeAuth = async (code) => {
  const auth = getAuth();
  const { verificationId } = window.confirmationResult;

  try {
    const credential = await auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );
    // User is successfully signed in
    const auth = await auth().signInWithCredential(credential);
    return { isValid: true, credential, auth };
  } catch (e) {
    console.log("Verification Code Confrim Error:", e);
    return { isValid: false, error: e };
  }
};

const checkExisitingReservation = async (venue) => {
  const uid = localStorage.getItem("uid");
  const path = `/business/${venue.restaurant.id}/locations/${venue.id}/checks`;

  const docRef = doc(db, path, uid);
  const docSnap = await getDoc(docRef);
  const phoneNumber = localStorage.getItem("userPhoneNumber");
  if (docSnap.exists()) {
    return docSnap.data();
  }
  return null;
};

const saveReservation = async (data) => {
  const phoneNumber = localStorage.getItem("userPhoneNumber");
  const uid = localStorage.getItem("uid");

  const {
    numPeople,
    selectedTime,
    specialRequest,
    selectedOccasion,
    selectedAllergies,
    reservationDate,
    reservationCode,
    venue,
  } = data;

  const formattedDate = reservationDate?.toISOString()?.split("T")?.[0];
  const path = `/business/${venue.restaurant.id}/locations/${venue.id}/checks`;

  const payload = {
    // arrivedAt: selectedTime === 'ASAP' ? new Date() : null,
    // arrivedAt: selectedTime === 'ASAP' ? null : new Date(),
    arrivedAt: selectedTime === "ASAP" ? "ASAP" : new Date(),
    checkType: "Reservation",
    // checkedInAt: selectedTime === 'ASAP' ? new Date() : null,
    // checkedInAt: selectedTime === 'ASAP' ? null : new Date(),
    checkedInAt: selectedTime === "ASAP" ? "ASAP" : new Date(),
    code: `${reservationCode}`,
    createdAt: new Date(),
    estimatedDuration: 60,
    estimatedWait: 30,
    guest: {
      authId: uid,
      id: phoneNumber,
      phone: phoneNumber,
    },
    guestCount: numPeople,
    guestPhoneNumbers: [phoneNumber],
    guests: [
      {
        authId: uid,
        id: phoneNumber,
        phone: phoneNumber,
      },
    ],
    id: uuidv4(),
    isBooking: true,
    isWalkin: selectedTime === "ASAP",
    notes: specialRequest,
    reservationDate: reservationDate,
    reservedAt: new Date(),
    seated: false,
    sortDate: formattedDate,
    stage: "Booked",
    status: "Reserved",
    tags: [
      { category: "Occasion", title: selectedOccasion },
      ...selectedAllergies.map((itm) => {
        return { category: "Allergies", title: itm };
      }),
    ],
    venue: venue,
    visitNotes: specialRequest,
  };
  try {
    let reservation = await setDoc(doc(db, path, uid), payload);
    return { result: true, reservation };
  } catch (e) {
    return { result: false };
  }
};

const sendOrder = async (venue, cart) => {
  try {
    const uid = localStorage.getItem("uid");
    const path = `/business/${venue.restaurant.id}/locations/${venue.id}/checks`;
    const reservationRef = doc(db, path, uid);
    await updateDoc(reservationRef, { lineItems: cart });
  } catch (error) {
    console.log("error while saving order", error);
  }
};

const updatePayment = async (venue, amount) => {
    try{
      const uid = localStorage.getItem("uid");
      const path = `/business/${venue.restaurant.id}/locations/${venue.id}/checks`;
      const reservationRef = doc(db, path, uid);
      // const data = {};
      // const payments = "payments";
    
      // const phoneNumber = localStorage.getItem("userPhoneNumber");
      // const tipAmount = 0;
      // data[payments] = [
      //   {
      //     amount: amount,
      //     customerID: uid,
      //     orderIds: [phoneNumber],
          // tipAmount: tipAmount,
        // },
      // ];
      // const xdata = data;
      // await updateDoc(reservationRef, xdata);
      const docSnap = await getDoc(reservationRef);
      if (docSnap?.data()?.id) {
    
        const newOrderRef = await addDoc(Orders, {
          orderItems: docSnap?.data()?.lineItems,
          userId: uid,
          orderStatus: "ORDER_PLACED",
          paymentStatus: "PAID",
          restaurant: venue?.restaurant,
          createdAt: new Date(),
          amount : amount
        });
      }
    }
    catch(e){
     console.log("error in saving new order",e)
    }
};

const joinTableSession = async (venue) => {
  const phoneNumber = localStorage.getItem("userPhoneNumber");
  const uid = localStorage.getItem("uid");

  const path = `/business/${venue.restaurant.id}/locations/${venue.id}/checks`;

  const reservationRef = doc(db, path, uid);

  // Check if the document exists
  const reservationSnapshot = await getDoc(reservationRef);

  if (reservationSnapshot.exists()) {
    // Document exists, perform update
    const updatedReserve = await updateDoc(reservationRef, {
      guests: arrayUnion({
        authId: uid,
        id: phoneNumber,
        phone: phoneNumber,
      }),
    });
  } else {
    // Document does not exist, create it
    const newReservationData = {
      guests: [
        {
          authId: uid,
          id: phoneNumber,
          phone: phoneNumber,
        },
      ],
    };
    // Create the document with merge option to update if it exists or create if it doesn't
    const result = await setDoc(reservationRef, newReservationData, {
      merge: true,
    });
    console.log("Document created or updated successfully!");
  }
};


const getOrderList = async () => {
  try {
    const ordersCollectionRef = collection(db, 'orders');
    const uid = localStorage.getItem("uid");

    // Create a query to filter orders by userId
    const q = query(ordersCollectionRef, where('userId', '==', uid));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Iterate through the documents in the query snapshot
    const orders = [];
    querySnapshot.forEach((doc) => {
      orders.push({ id: doc.id, ...doc.data() });
    });

    return orders;
  } catch (error) {
    console.error('Error fetching orders: ', error);
    throw new Error('Failed to fetch orders');
  }
};



// const functions = firebase.functions()
// console.log("functions == ",functions);

export {
  app,
  functions,
  httpsCallable,
  httpsCallableFromURL,
  getMenu,
  getRestaurants,
  getSingleRestaurantInfo,
  db,
  initRecaptcha,
  sendSMSVerificationCode,
  validateVerificationCode,
  validateVerificationCodeAuth,
  checkExisitingReservation,
  saveReservation,
  joinTableSession,
  sendOrder,
  updatePayment,
  getOrderList
};
