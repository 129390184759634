import { createSlice } from '@reduxjs/toolkit';

const initialState = {
totalAmount :0
};

const amountSlice = createSlice({
  name: 'totalAmount',
  initialState,
  reducers: {
    totalAmountHandler(state,data) {
        // console.log("total",data.payload)
      state.totalAmount = data.payload
    },
  },
});

// Export the generated action creators
export const { totalAmountHandler } = amountSlice.actions;

// Export the reducer
export default amountSlice.reducer;
